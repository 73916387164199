<template>
  <div>
  
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    
    }
  },
  mounted() {
  
  },
  methods: {
  
  }
}
</script>
